import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/content.js";
import Button from 'src/components/design-system/buttons/action.js';
import { P } from 'src/components/layouts/content/theme';
import SEO from 'src/components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Votre inscription est bien enregistrée`}</h2>
    <SEO title="Inscription enregistrée" mdxType="SEO" />
    <P className="mt-4" mdxType="P">
Un email va vous être envoyé rapidement pour vous confirmer l'inscription. 
    </P>
    <P className="" mdxType="P">
L'attribution par la Mairie de Paris des créneaux horaires des équipements aux associations <em>n'est pas définitive</em> au moment où nous ouvrons les inscriptions.En conséquence, <em>l'association se réserve la possibilité de vous proposer un autre créneau</em> ou de vous rembourser dans le cas où les créneaux choisis ne seraient pas disponibles à la rentrée.
    </P>
    <P className="font-semibold" mdxType="P">
Vous pouvez dès à présent créer votre espace personnel.
    </P>
    <P className="text-center mt-8" mdxType="P">
      <Button to="/activate" className="text-center m-auto" mdxType="Button">Créez votre
compte</Button>
    </P>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      